/*

Simple little controller used on funding round definition to redirect
the page when the user choose partner paperwork (this is because
certain partners may disable certain fields, so it's easier to reload
the page entirely and figure that out in Rails)

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

export default class PartnerPaperworkController extends Controller {
  static targets = ["dropdown"];

  change(e) {
    location.href = `/account/funding_rounds/${this.dropdownTarget.dataset.id}?step=1&partner_paperwork=${this.dropdownTarget.value}`
  }

}
