/*

Simple controller for a Cookie banner that sets a cookie to a given value
when the user accepts the cookie policy.

Required structure:

<div data-controller="cookie-consent">
  <span>Do you accept all cookies?</span>
  <button data-action="click->cookie-consent#all">Yes</button>
  <button data-action="click->cookie-consent#essential">Only essential</button>
</div>

*/

import {
  Controller
} from "@hotwired/stimulus";

$ = window.$

// Send cookie consent via Ajax. If we set the same cookie via Javascript, Safari's default privacy
// policy would only set it for one week, but server-set cookies can have any expiry date. Therefore
// we use Rails' cookies.permanent to set it
export default class CookieConsentController extends Controller {
  connect() {
  }

  essential() {
    $.post("/cookie-consent", { cookie_consent: "essential" })
    $(this.element).remove()
  }

  all() {
    $.post("/cookie-consent", { cookie_consent: "all" })
    $(this.element).remove()
  }
}
