import { Controller } from "@hotwired/stimulus";

export default class UniqueEmailController extends Controller {
  checkUniqueness(event) {
    const inputValue = event.target.value;
  
    clearTimeout(this.emailInputTimeout);
  
    this.emailInputTimeout = setTimeout(() => {
      if (window.existingShareholdingEmails.includes(inputValue)) {
        alert("You are inviting a new shareholder or investor using an existing email address (" + inputValue + ") but different name. You cannot use the same email address for two different and separate users.");
        event.target.value = "";
      }
    }, 1000);
  }

  disconnect() {
    clearTimeout(this.emailInputTimeout);
  }
}
